import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh`}</strong>{` -- create and manage ssh certificates`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh`}</strong>{` command group provides facilities to sign SSH certificates.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Generate a new SSH key pair and user certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh certificate joe@work id_ecdsa
`}</code></pre>
    <p>{`Generate a new SSH key pair and host certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh certificate --host internal.example.com ssh_host_ecdsa_key
`}</code></pre>
    <p>{`Add a new user certificate to the agent:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh login joe@example.com
`}</code></pre>
    <p>{`Remove a certificate from the agent:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh logout joe@example.com
`}</code></pre>
    <p>{`List all keys in the agent:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh list
`}</code></pre>
    <p>{`Configure a user environment with the SSH templates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh config
`}</code></pre>
    <p>{`Inspect an ssh certificate file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh inspect id_ecdsa-cert.pub
`}</code></pre>
    <p>{`Inspect an ssh certificate in the agent:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh list --raw joe@example.com | step ssh inspect
`}</code></pre>
    <p>{`List all the hosts you have access to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh hosts
`}</code></pre>
    <p>{`Login into one host:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ ssh internal.example.com
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "certificate/"
              }}>{`certificate`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sign a SSH certificate using the SSH CA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "check-host/"
              }}>{`check-host`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`checks if a certificate has been issued for a host`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "config/"
              }}>{`config`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`configures ssh to be used with certificates`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "fingerprint/"
              }}>{`fingerprint`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`print the fingerprint of an SSH public key or certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "hosts/"
              }}>{`hosts`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`returns a list of all valid hosts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "inspect/"
              }}>{`inspect`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`print the contents of an ssh certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "list/"
              }}>{`list`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`list public keys known to the ssh agent`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "login/"
              }}>{`login`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`adds a SSH certificate into the authentication agent`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "logout/"
              }}>{`logout`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`removes a private key from the ssh-agent`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "needs-renewal/"
              }}>{`needs-renewal`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Check if an SSH certificate needs to be renewed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "proxycommand/"
              }}>{`proxycommand`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`proxy ssh connections according to the host registry`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "rekey/"
              }}>{`rekey`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`rekey a SSH certificate using the SSH CA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "renew/"
              }}>{`renew`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`renew a SSH certificate using the SSH CA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "revoke/"
              }}>{`revoke`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`revoke a SSH certificate using the SSH CA`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      